import * as jwt from 'jsonwebtoken'

const BACKEND_URL = process.env.GATSBY_QBP_TOKEN_URL

const BIMP_TOKEN_KEY = 'BIMP_TOKEN'
const EXP_THRESHOLD_SEC = 10 * 60

export async function getTokenForUser(username: string, password: string): Promise<string> {
    let token = getTokenFromLocalStorage()
    if (!token) {
        token = await fetchTokenForUser(username, password)

        if (token) {
            saveTokenToLocalStorage(token)
        }
    }

    return token
}

async function fetchTokenForUser(username: string, password: string): Promise<string> {
    const response = await fetch(`${BACKEND_URL}/authenticate`, {
        headers: { 'content-type': 'application/json' },
        method: 'post',
        body: JSON.stringify({ username, password })
    })
    const data = await response.json()

    return data.token
}

function getTokenFromLocalStorage(): string | null {
    const token = localStorage.getItem(BIMP_TOKEN_KEY)
    if (!token) return null

    try {
        const decoded = jwt.decode(token)
        if (!decoded) {
            return null
        }

        const expiration = decoded.exp
        const currTime = new Date().getTime() / 1000

        if (expiration - currTime < EXP_THRESHOLD_SEC) {
            return null
        }

        return token
    } catch (e) {
        console.error('Failed to decode token', e)
    }

    return null
}

function saveTokenToLocalStorage(token: string) {
    localStorage.setItem(BIMP_TOKEN_KEY, token)
}
