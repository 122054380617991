import React from 'react'
import { parse } from 'query-string'
import { bimpConfig } from '../bimpConfig'
import { BimpApp } from 'bimp-ui'
import { Config } from 'bimp-ui/src/types/Config'

import creditSample from 'raw-loader!../samples/ch7_CreditAppSimulation_3x-trim10.bpmn'
import { getTokenForUser } from '../tokenHelper'

export type BimpVariant = 'academic' | 'trial' | 'members'

export interface BimpWrapperProps {
    variant: BimpVariant
}

interface State {
    config: Partial<Config>
}

const SAMPLES = {
    credit_card_application: creditSample
}

export class BimpWrapper extends React.Component<BimpWrapperProps, State> {
    state: State = {
        config: {
            ...bimpConfig
        }
    }

    private uploadedData?: {
        fileName: string
        fileContent: string
    }

    constructor(props: BimpWrapperProps) {
        super(props)
        if (typeof window !== 'undefined') {
            const { sample } = parse(window.location.search)

            const storedFile = localStorage.getItem('BIMP_UPLOADED_CONTENT')
            if (storedFile) {
                this.uploadedData = JSON.parse(storedFile)
                localStorage.removeItem('BIMP_UPLOADED_CONTENT')
            } else if (sample && typeof sample === 'string' && SAMPLES[sample]) {
                this.uploadedData = {
                    fileName: sample + '.bpmn',
                    fileContent: btoa(SAMPLES[sample])
                }
            }
        }
    }

    componentDidMount() {
        this.fetchTokenAndInit()
    }

    componentDidUpdate(prevProps: BimpWrapperProps) {
        if (prevProps.variant !== this.props.variant) {
            this.setState({ config: { ...this.state.config, jwtAuth: { token: undefined } } })
            this.fetchTokenAndInit()
        }
    }

    private async fetchTokenAndInit() {
        let token: string | undefined
        if (this.props.variant !== 'trial') {
            token = await getTokenForUser('free', 'free')
        } else {
            return
        }

        this.setState({ config: { ...this.state.config, jwtAuth: { token } } })
    }

    render() {
        const { config } = this.state

        const initialFiles = this.uploadedData
            ? [{ name: this.uploadedData.fileName, contents: atob(this.uploadedData.fileContent) }]
            : undefined

        const canLoad = this.props.variant === 'trial' || config.jwtAuth?.token

        if (typeof window === 'undefined') {
            return <div />
        }

        return (
            <div className="bimp-wrapper">
                <div className="container is-flex">
                    {canLoad && <BimpApp config={config as Config} initialFiles={initialFiles} />}
                    {!canLoad && (
                        <button
                            className="button is-loading is-white is-large"
                            style={{
                                margin: 'auto'
                            }}
                        />
                    )}
                </div>
            </div>
        )
    }
}
