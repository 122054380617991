import React from 'react'
import { Link } from 'gatsby'

import Layout from '../../components/Layout'
import { BimpWrapper, BimpVariant } from '../../components/BimpWrapper'

export interface SimulatorPageProps {
    variant?: BimpVariant
}

export default class SimulatorPage extends React.Component<SimulatorPageProps> {
    static defaultProps: Partial<SimulatorPageProps> = {
        variant: 'academic'
    }

    render() {
        const { variant } = this.props
        return (
            <Layout>
                <section className="section" style={{ paddingBottom: 0 }}>
                    <div className="container">
                        <div className="content">
                            {/* <h1>{title}</h1> */}
                            <p className="subtitle">
                                BIMP is a fast and simple web-based user interface to simulate business process models using the QBP
                                Simulator.
                            </p>
                            <p>
                                See the <Link to="/simulator/get-started">getting started guide</Link> to read more about the features. BIMP
                                can be used for free for academic and trial purposes. Choose the version below:
                            </p>

                            <div className="buttons">
                                <Link className={`button is-outlined ${variant === 'academic' && 'is-active'}`} to="/simulator">
                                    Academic
                                </Link>
                                <Link className={`button is-outlined ${variant === 'trial' && 'is-active'}`} to="/simulator/trial">
                                    Trial
                                </Link>
                                <Link className={`button is-outlined ${variant === 'members' && 'is-active'}`} to="/simulator/members">
                                    Members
                                </Link>
                            </div>
                            <h2>{this.getTitle()}</h2>
                            {variant === 'academic' && (
                                <p>Academic version of BIMP is supported by University of Tartu and the Estonian Research Council.</p>
                            )}
                            <BimpWrapper variant={variant as BimpVariant} />
                        </div>
                    </div>
                </section>
            </Layout>
        )
    }

    private getTitle() {
        const { variant } = this.props

        if (variant === 'academic') return 'BIMP - Academic'

        if (variant === 'trial') return 'BIMP - Trial'

        return 'Members only'
    }
}
